const XAPIKEY = "coN21di1202VII01Ed0OnNiMDa2P3pM0";
//const basePath = "https://ath-apiqa.dvconsulting.org/";
//const basePath ="https://openchat-api-v2.hanatour.com/";
const basePath = "https://hanatour-api2.dvconsulting.org/";
//const basePath ="https://hanatour-uat.dvconsulting.org/";
// UAT
// https://hanatour-uat.dvconsulting.org/
// DEV
// https://hanatour-api2.dvconsulting.org/
// live
// https://openchat-api.hanatour.com/
const versionPath = "api/v1/";
const baseURL = basePath + versionPath;
export {
    XAPIKEY,
    baseURL,
    basePath
}